export const SET_FIXTURES = 'SET_FIXTURES';
export const SET_BANNERS = 'SET_BANNERS';
export const SET_GAME_DATA = 'SET_GAME_DATA';
export const SET_GAMES = 'SET_GAMES';
export const SET_PREV_FIXTURES = "SET_PREV_FIXTURES";
export const SET_NEXT_FIXTURES = "SET_NEXT_FIXTURES";
export const SET_ALL_FIXTURES = "SET_ALL_FIXTURES";
export const SET_FIXTURES_DATA = "SET_FIXTURES_DATA";
export const UPDATE_LIVE_FIXTURES = "UPDATE_LIVE_FIXTURES";
export const SET_SEASON_DATA = "SET_SEASON_DATA";
export const SET_PAST_FIXTURES = "SET_PAST_FIXTURES";
export const RESET_PAST_FIXTURES = "RESET_PAST_FIXTURES";