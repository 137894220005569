import React, { lazy, Suspense } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
  } from "react-router-dom";
import { AppLoader } from "../components/ContentLoader";

const AllRoutes = () => {
  const Home = lazy(() => import("../pages/home"));
  return (
    <>
      <Suspense
        fallback={
          <AppLoader
            h="45"
            w="45"
            style={{
              margin: "15rem auto",
              width: "25%",
            }}
          />
        }
      >
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<Home />}
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default AllRoutes;