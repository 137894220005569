import { useRef } from 'react';
import logo from './logo.svg';
import { ToastContainer, Slide } from 'react-toastify';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import AllRoutes from './routes/Routes';


function App() {
  const tawkMessengerRef = useRef();
  const privacyCheck = localStorage.getItem("privacyStatus");
  
  return (
    <div className="App overflow-y-scroll">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
      <AllRoutes/>
      {privacyCheck === "accepted" && (
        <TawkMessengerReact
          propertyId="60e1aaa6d6e7610a49a98798"
          widgetId="1f9oo56t6"
          useRef={tawkMessengerRef}
        />
      )}
    </div>
  );
}

export default App;
