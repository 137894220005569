import React from 'react';
import {Puff,ProgressBar} from 'react-loader-spinner';

// const ContentLoader = React.memo(props => (
//   <div
//     style={
//       props.style || {
//         margin: '15rem auto',
//         width: '5%',
//       }
//     }
//   >
//     <Loader
//       type={props.type || 'Puff'}
//       color="#7A2367"
//       height={props.h || 60}
//       width={props.w || 60}
//     />
//   </div>
// ));
// export default ContentLoader;

// export const AppLoader = props => (
//     <div
//       style={
//         props.style || {
//           margin: '15rem auto',
//           width: '25%',
//         }
//       }
//     >
//       <Puff
//         height="80"
//         width="80"
//         radisu={1}
//         color="#7A2367"
//         ariaLabel="puff-loading"
//         wrapperStyle={{}}
//         wrapperClass=""
//         visible={true}
//       />
//     </div>
// );

export const AppLoader = props => (
  <div
    style={
      props.style || {
        margin: '15rem auto',
        width: '25%',
      }
    }
  >
    <div className='lds-ripple'>
      <div></div>
      <div></div>
    </div>
  </div>
);

export const StatsLoader = (props) => (
    <div
    className=" self-center"
        style={
        props.style || {
            margin: 'auto',
            width: '100%',
            height: '30px'
        }
        }
    >
      <ProgressBar
        height="50"
        width="200"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass="progress-bar-wrapper"
        borderColor = '#D9D9D9'
        barColor = '#201837'
      />
    </div>
);