import {
    SET_GAME_DATA,SET_FIXTURES,SET_BANNERS,SET_GAMES,SET_NEXT_FIXTURES,SET_PREV_FIXTURES,
    SET_ALL_FIXTURES, SET_FIXTURES_DATA, UPDATE_LIVE_FIXTURES, SET_SEASON_DATA,
    SET_PAST_FIXTURES, RESET_PAST_FIXTURES } from "../types";
   
   const INITIAL_STATE = {
     groupid: "", 
     prevGroupid: "",
     nextGroupid:"",
     fixtures: [], 
     prevFixtures: [],
     nextFixtures: [],
     allFixtures: [],
     rewards: [],
     title: "",
     prevTitle: "",
     nextTitle: "",
     nextGameStart: "",
     banners: [],
     games: [],
     previousGames: [],
     time: {},
     seasonsData: {},
     pastFixtures: {},
     confirmedWinners: [],
   };
   
   // eslint-disable-next-line import/no-anonymous-default-export
   // staging-rt5.1
   export default (state = INITIAL_STATE, action) => {

    const updateLiveFixtures = (state, action) => {
        //console.log('Before updating prevFixtures:', JSON.stringify(state.prevFixtures));

        const updatedPrevFixtures = state.prevFixtures.map((item) => {
            if (item.id === action.payload.data.id) {
                // Create a shallow copy of the item to avoid direct mutations
                const updatedItem = { ...item };

                //console.log('Updating prevFixture:', JSON.stringify(updatedItem));

                // Update score and status
                updatedItem.score = action.payload.data.score;
                updatedItem.status = action.payload.data.status;

                // Update ft_score only if status is "FINISHED"
                if (action.payload.data.status === "FINISHED") {
                    updatedItem.ft_score = action.payload.data.ft_score;
                }

                // Update time_elapsed
                updatedItem.time_elapsed = action.payload.data.time_elapsed;

                return updatedItem;
            }
            return item;
        });

        //console.log('After updating prevFixtures:', JSON.stringify(updatedPrevFixtures));

        return updatedPrevFixtures;
    };


     switch (action.type) {
        case SET_GAME_DATA:
        return{
            ...state,
            fixtures: action.payload,
            time: action.time,
            rewards: action.rewards
        }
        case SET_FIXTURES: 
        return{
            ...state,
            fixtures: action.payload
        }
        case UPDATE_LIVE_FIXTURES: 
        return{
            ...state,
            prevFixtures: updateLiveFixtures(state,action)
        }
        case SET_FIXTURES_DATA: 
        return{
            ...state,
            prevGroupid: action.payload.previousGameID,
            nextGroupid: action.payload.nextGameID,
            groupid: action.payload.currentGameID,
            prevTitle: action.payload.previousGameName,
            nextTitle: action.payload.nextGameName,
            title: action.payload.currentGameName,
            nextGameStart: action.payload.nextGameStart,
            liveRound: action.payload.liveRound,
            priority: action.payload.priority,
        }
        case SET_PREV_FIXTURES: 
        return{
            ...state,
            prevFixtures: action.payload,
            time: action.time,
            rewards: action.rewards,
            confirmedWinners: action.confirmedWinners,
        }
        case SET_NEXT_FIXTURES: 
        return{
            ...state,
            nextFixtures: action.payload,
            time: action.time,
            rewards: action.rewards
        }
        case SET_BANNERS: 
        return{
            ...state,
            banners: action.payload
        }
        case SET_GAMES: 
        return{
            ...state,
            previousGames: action.payload,
        }
        case SET_ALL_FIXTURES:
        return{
            ...state,
            allFixtures: action.payload,
        }
        case SET_SEASON_DATA:
        return{
            ...state,
            seasonsData: action.payload,
        }
        case SET_PAST_FIXTURES:
        return{
            ...state,
            pastFixtures: {
                ...state.pastFixtures,
                [action.payload.gameid] : action.payload.data,
            },
        }
        case RESET_PAST_FIXTURES:
        return{
            ...state,
            pastFixtures: action.payload,
        }
       default:
         return state;
     }
   };
