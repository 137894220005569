import { combineReducers } from "redux";
import predict from "./predictReducer";
import loading from "./loadingReducer";

export const appReducer = combineReducers({
  predict,
  loading
});

const rootReducer = (state, action) => {
  // if (action.type === LOGOUT_USER) {
  //   localStorage.clear();
  //   state = undefined;
  // }

  return appReducer(state, action);
};

export default rootReducer;